import axios from 'axios'
import { URL } from './constants'

export const getOrderCount = async () => {
    const token = await localStorage.getItem('token')
    const headers = {
        'Authorization': `Bearer ${token}`
    }
    return (
        axios.get(`${URL}/getadminordercount`, { headers: headers })
        .then((res) => res)
        .then((err) => err)
    )
}

export const getOrder = async ({before, after, limit}) => {
    const token = await localStorage.getItem('token')
    const headers = {
        'Authorization': `Bearer ${token}`
    }
    const data = {
        before,
        after,
        limit,
    }
    return (
        axios.post(`${URL}/getadminorder`, data, { headers: headers })
        .then((res) => res)
        .then((err) => err)
    )
}

export const completeOrder = async ({id}) => {
    const token = await localStorage.getItem('token')
    const headers = {
        'Authorization': `Bearer ${token}`
    }
    const data = {
        _id: id,
    }
    return (
      axios.post(`${URL}/completeorder`, data, { headers: headers })
        .then((res) => res)
        .then((err) => err)
    )
}

// getorder
// createorder
