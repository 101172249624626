import React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { DropzoneArea } from 'material-ui-dropzone';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ButtonAppBar from '../../_component/appBar'
import { createProduct } from "../../_api/product"
import FormLabel from "@material-ui/core/FormLabel"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Radio from "@material-ui/core/Radio"
import FormControl from "@material-ui/core/FormControl"

class CreateProductScreen extends React.Component {
  state = {
    name: '',
    size: '',
    price: '',
    files: '',
    is_package: '',
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleFileChange = (files) =>{
    this.setState({
      files: files
    });
  }

  _createProduct = async () => {
    if (!this.state.name || !this.state.size || !this.state.price || !this.state.files || !this.state.is_package) {
      toast('Please fill all the fields.', { type: toast.TYPE.ERROR, autoClose: 5000 })
    } else {
      const getBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })

      const res = await createProduct({
        name: this.state.name,
        size: this.state.size,
        price: this.state.price,
        image: await getBase64(this.state.files[0]),
        is_package: this.state.is_package,
      })
      if (res.status === 200) {
        toast('Product created.', {
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
          onClose: () => this.props.history.goBack()
        })
      }
    }
  }

  _dropzoneElement = (classes) => (
    <div className={classes.dropzoneStyle}>
      <DropzoneArea
        acceptedFiles={['image/jpeg', 'image/png']}
        filesLimit={1}
        onChange={this.handleFileChange.bind(this)}
      />
    </div>
  )

  _submitButton = (classes) => (
    <div className={classes.submitButtonContainer}>
      <Button variant="contained" color="primary" onClick={() => this._createProduct()}>
        Submit
      </Button>
    </div>
  )

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <CssBaseline />
        <ButtonAppBar />
        <ToastContainer />
        <form className={classes.container} noValidate autoComplete="off">
          <TextField
            required
            id="outlined-name"
            label="ชื่อสินค้า"
            className={classes.textField}
            value={this.state.name}
            onChange={this.handleChange('name')}
            margin="normal"
            variant="outlined"
          />
          <TextField
            required
            id="outlined-uncontrolled"
            label="ขนาด"
            className={classes.textField}
            value={this.state.size}
            onChange={this.handleChange('size')}
            margin="normal"
            variant="outlined"
            helperText="ต้องระบุหน่วยด้วย"
          />
          <TextField
            required
            type="number"
            id="outlined-required"
            label="ราคา"
            className={classes.textField}
            value={this.state.price}
            onChange={this.handleChange('price')}
            margin="normal"
            variant="outlined"
          />
        </form>
        <div className={classes.container}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Subscription</FormLabel>
            <RadioGroup
              aria-label="subscription"
              name="subscription1"
              value={this.state.is_package}
              onChange={(event) => this.setState({is_package: event.target.value})}
            >
              <FormControlLabel value='true' control={<Radio />} label="Is subscription" />
              <FormControlLabel value='false' control={<Radio />} label="Is not subscription" />
            </RadioGroup>
          </FormControl>
        </div>
        { this._dropzoneElement(classes) }
        { this._submitButton(classes) }
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: 20,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dropzoneStyle: {
    marginTop: 40,
  },
  submitButtonContainer: {
    display: 'flex',
    marginTop: 30,
    justifyContent: 'center',
    marginBottom: 30,
  },
});

CreateProductScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CreateProductScreen);
