import React, { Component } from 'react'
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom'
import { PrivateRoute } from './_component/privateRoute'
import Signin from './screens/signin'

import ProductScreen from './screens/product'
import CreateProductScreen from './screens/product/createProduct'
import OrderScreen from './screens/order'
import PackageScreen from './screens/package'
import SlipScreen from './screens/bankslip'

class App extends Component {
  componentDidMount = () => {
    document.title = "Nature Food"
  }

  render() {
    return (
      <Router>
        <div>
          <Route exact path='/' component={Signin} />
          <PrivateRoute path='/product/list' component={ProductScreen} />
          <PrivateRoute path='/product/create' component={CreateProductScreen} />
          <PrivateRoute path='/order/list' component={OrderScreen} />
          <PrivateRoute path='/package/list' component={PackageScreen} />
          <PrivateRoute path='/slip/list' component={SlipScreen} />
        </div>
      </Router>
    );
  }
}

export default App;
