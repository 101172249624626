import axios from 'axios'
import {URL} from './constants'

export const getPackageCount = async () => {
  const token = localStorage.getItem('token')
  const headers = {
    'Authorization': `Bearer ${token}`
  }
  return (
    axios.get(`${URL}/getadminsubscriptioncount`, { headers })
      .then((res) => res)
      .then((err) => err)
  )
}

export const getPackage = async ({ before, after, limit }) => {
  const token = localStorage.getItem('token')
  const headers = {
    'Authorization': `Bearer ${token}`
  }
  const data = {
    before,
    after,
    limit,
  }
  return axios.post(`${URL}/getadminsubscription`, data, { headers })
    .then((res) => res)
    .catch((err) => err)
}

export const completePackage = async ({id}) => {
  const token = await localStorage.getItem('token')
  const headers = {
    'Authorization': `Bearer ${token}`
  }
  const data = {
    _id: id,
  }
  return (
    axios.post(`${URL}/completesubscription`, data, { headers: headers })
      .then((res) => res)
      .then((err) => err)
  )
}

// getsubscription
// createsubscription
