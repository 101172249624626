import axios from 'axios'
import {URL} from './constants'

export const getProduct = async () => {
  const token = await localStorage.getItem('token')
  const headers = {
    'Authorization': `Bearer ${token}`
  }
  return (
    axios.get(`${URL}/getproduct`, {headers})
      .then((res) => res)
      .then((err) => err)
  )
}

export const createProduct = async ({name, price, size, image, is_package}) => {
  const token = await localStorage.getItem('token')
  const headers = {
    'Authorization': `Bearer ${token}`
  }
  const data = {
    name,
    price,
    size,
    image,
    is_package,
  }
  return axios.post(`${URL}/createproduct`, data, {headers})
}

export const editProduct = async ({id, name, price, size, is_package, image}) => {
  const token = await localStorage.getItem('token')
  const headers = {
    'Authorization': `Bearer ${token}`
  }
  const data = {
    id,
    name,
    price,
    size,
    is_package,
    image,
  }
  return axios.post(`${URL}/editproduct`, data, {headers})
}

export const removeProduct = async ({id}) => {
  const token = await localStorage.getItem('token')
  const headers = {
    'Authorization': `Bearer ${token}`
  }
  const data = {
    id,
  }
  return axios.post(`${URL}/removeproduct`, data, {headers})
}

// getpackageproduct
// createproduct
// editproduct
// removeproduct
