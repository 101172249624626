import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { Edit } from '@material-ui/icons';

import { getPackageCount, getPackage } from '../../_api/package'
import moment from "moment"

class PackageTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      rowsPerPage: 10,
      packages: [],
      count: 0,
    }
  }

  componentDidMount = async () => {
    const countres = await getPackageCount()
    await this.setState({ count: countres.data })
    const res = await getPackage({ limit: this.state.rowsPerPage })
    const packages = res.data
    console.log('=> package', packages)
    await this.setState({packages})
  }

  handleChangePage = async (event, page) => {
    let res
    if (this.state.page < page) {
      res = await getPackage({ after: this.state.packages[this.state.packages.length - 1]._id, limit: this.state.rowsPerPage })
      console.log('next page res:', res)
    } else {
      res = await getPackage({ before: this.state.packages[0]._id, limit: this.state.rowsPerPage })
      console.log('previous page res:', res)
    }
    await this.setState({ page })
    const packages = res.data
    await this.setState({ packages })
  }

  _renderPagination = () => {
    return (
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={this.state.count}
        rowsPerPage={this.state.rowsPerPage}
        page={this.state.page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onChangePage={this.handleChangePage}
      />
    )
  }

  render() {
    const { classes } = this.props;

    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <CustomTableCell>Date</CustomTableCell>
              <CustomTableCell>Deliver Week</CustomTableCell>
              <CustomTableCell>Status</CustomTableCell>
              <CustomTableCell></CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.packages.map(order => {
              return (
                <TableRow className={classes.row} key={order._id}>
                  <CustomTableCell>{moment(order.created_time).format('LLL')}</CustomTableCell>
                  <CustomTableCell>{order.deliverweek}</CustomTableCell>
                  <CustomTableCell>{order.status}</CustomTableCell>
                  <CustomTableCell><Button onClick={() => this.props.handleOpen(order)}><Edit /></Button></CustomTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        { this._renderPagination() }
      </Paper>
    );
  }
}

PackageTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
});

export default withStyles(styles)(PackageTable);
