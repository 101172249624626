import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { Edit } from '@material-ui/icons';

import { getSlipCount, getSlip } from '../../_api/payment'
import {getOrder} from "../../_api/order"
import moment from "moment"

class SlipTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      count: 0,
      rowsPerPage: 10,
      slips: [],
    }
  }

  componentDidMount = async () => {
    const countres = await getSlipCount()
    this.setState({ count: countres.data })
    const res = await getSlip({ limit: this.state.rowsPerPage })
    const slips = res.data
    await this.setState({ slips })
  }

  handleChangePage = async (event, page) => {
    let res
    if (this.state.page < page) {
      res = await getSlip({ after: this.state.slips[this.state.slips.length - 1]._id, limit: this.state.rowsPerPage })
    } else {
      res = await getSlip({ before: this.state.slips[0]._id, limit: this.state.rowsPerPage })
    }
    await this.setState({ page })
    const slips = res.data
    await this.setState({ slips })
  }

  _renderPagination = () => {
    return (
      <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={this.state.count}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
    )
  }

  render() {
    const { classes } = this.props;

    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <CustomTableCell>Date</CustomTableCell>
              <CustomTableCell>User</CustomTableCell>
              <CustomTableCell>Status</CustomTableCell>
              <CustomTableCell></CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              this.state.slips.map(slip => {
                return (
                  <TableRow className={classes.row} key={slip._id}>
                    <CustomTableCell>{moment(slip.created_time).format('LLL')}</CustomTableCell>
                    <CustomTableCell>{slip.email}</CustomTableCell>
                    <CustomTableCell>{slip.status}</CustomTableCell>
                    <CustomTableCell><Button onClick={() => this.props.handleOpen(slip)}><Edit /></Button></CustomTableCell>
                  </TableRow>
                );
              })
            }
          </TableBody>
        </Table>
        { this._renderPagination() }
      </Paper>
    );
  }
}

SlipTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
});

export default withStyles(styles)(SlipTable);
