import React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';

import ButtonAppBar from '../../_component/appBar'
import SlipTable from '../../_component/bankslip/slipTable';
import {rechargeSlip} from "../../_api/payment";

class SlipScreen extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      slip: '',
      open: false,
      rechargeCredit: 0,
    }
  }

  handleOpen = async (slip) => {
    console.log({slip})
    await this.setState({slip})
    this.setState({open: true})
  };

  handleClose = () => {
    this.setState({open: false});
  };

  _rechargeCreditSubmit = async ({slipValid}) => {
    const res = await rechargeSlip({id: this.state.slip._id, credit: slipValid ? this.state.rechargeCredit : -1})
    console.log('res', res)
    this.setState({open: false})
  }

  _renderTextField = () => {
    if (this.state.slip.status === 'pending') {
      return (
        <TextField
          id="credit"
          label="Credit Recharge"
          style={{marginBottom: 5}}
          value={this.state.rechargeCredit}
          onChange={(event) => this.setState({rechargeCredit: event.target.value})}
          margin="normal"
        />
      )
    }
  }

  _renderButton = () => {
    if (this.state.slip.status === 'pending') {
      return (
        <div>
          <br/>
          <Button style={{marginRight: 20}} onClick={() => this._rechargeCreditSubmit({slipValid: false})}
                  color="secondary" variant="contained">
            Remove
          </Button>
          <Button onClick={() => this._rechargeCreditSubmit({slipValid: true})} color="primary" variant="contained">
            Submit
          </Button>
        </div>
      )
    }
  }

  _modalComponent = (classes) => {
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.state.open}
        onClose={this.handleClose}
      >
        <div style={getModalStyle()} className={classes.paper}>
          <div>
            <Typography style={orderTextMargin} variant="h6" id="modal-title">
              Slip Information
            </Typography>
            <img style={{height: 300}} src={`data:image/png;base64, ${this.state.slip.data}`}/>
            <Typography style={{marginTop: 5}}>User: {this.state.slip.email}</Typography>
            <Typography style={{marginBottom: 5}}>Status: {this.state.slip.status}</Typography>
            {this._renderTextField()}
          </div>
          {this._renderButton()}
        </div>
      </Modal>
    )
  }

  render() {
    const {classes} = this.props;

    return (
      <React.Fragment>
        <CssBaseline/>
        <ButtonAppBar/>
        <div style={pageMargin}>
          <Typography variant="h4" gutterBottom component="h2">
            Slip Dashboard
          </Typography>
          <div>
            <SlipTable handleOpen={(item) => this.handleOpen(item)}/>
          </div>
        </div>
        {this._modalComponent(this.props.classes)}
      </React.Fragment>
    );
  }
}

SlipScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const pageMargin = {
  margin: 50,
}

const orderTextMargin = {
  marginBottom: 20,
}

function getModalStyle() {
  return {
    top: `5%`,
    left: `28%`,
  };
}

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 70,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
  },
  formControl: {
    margin: theme.spacing.unit * 3,
  },
});

export default withStyles(styles)(SlipScreen);
