import axios from 'axios'
import {URL} from './constants'

export const getSlipCount = async () => {
  const token = localStorage.getItem('token')
  const headers = {
    'Authorization': `Bearer ${token}`
  }
  return axios.get(`${URL}/getadminslipcount`, {headers})
    .then((res) => res)
    .catch((err) => err)
}

export const getSlip = async ({before, after, limit}) => {
  const token = localStorage.getItem('token')
  const headers = {
    'Authorization': `Bearer ${token}`
  }
  const data = {
    before,
    after,
    limit,
  }
  return axios.post(`${URL}/getadminslip`, data, {headers})
    .then((res) => res)
    .catch((err) => err)
}

export const rechargeSlip = async ({id, credit}) => {
  console.log('recharge api')
  console.log(id, credit)
  const token = localStorage.getItem('token')
  const headers = {
    'Authorization': `Bearer ${token}`
  }
  const data = {
    _id: id,
    amount: credit,
  }
  return axios.post(`${URL}/updateslip`, data, {headers})
}

// getslip
// updateslip
