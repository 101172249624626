import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { editProduct, removeProduct } from "../../_api/product"
import ButtonAppBar from '../../_component/appBar'
import ProductTable from '../../_component/product/productTable'

class ProductScreen extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      editProductId: '',
      editProductName: '',
      editProductSize: '',
      editProductPrice: '',
      editProductSubscription: '',
      editProductImage: '',
    }
  }

  _createProduct = () => {
    this.props.history.push('/product/create')
  };

  _removeProduct = async () => {
    const res = await removeProduct({id: this.state.editProductId})
    toast(`Successfully remove product ${this.state.editProductName}`, { type: toast.TYPE.SUCCESS, autoClose: 5000 })
    this.handleClose()
  }

  _submitEditProduct = async () => {
    const res = await editProduct({
      id: this.state.editProductId,
      name: this.state.editProductName,
      size: this.state.editProductSize,
      price: this.state.editProductPrice,
      is_package: this.state.editProductSubscription,
      image: this.state.editProductImage,
    })
    if (res.status === 200) {
      toast(`Successfully edit product ${this.state.editProductName}`, { type: toast.TYPE.SUCCESS, autoClose: 5000 })
    } else {
      toast(`Cannot edit product ${this.state.editProductName}`, { type: toast.TYPE.ERROR, autoClose: 5000 })
    }
    this.handleClose()
  }

  handleOpen = async (product) => {
    await this.setState({
      editProductId: product._id,
      editProductName: product.name,
      editProductSize: product.size,
      editProductPrice: product.price,
      editProductSubscription: product.is_package,
      editProductImage: product.image,
    })
    this.setState({ open: true })
  };

  handleClose = () => {
    this.setState({ open: false });
    this.forceUpdate()
  };

  _modalComponent = (classes) => (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={this.state.open}
      onClose={this.handleClose}
    >
      <div style={getModalStyle()} className={classes.paper}>
        <Typography variant="h6" id="modal-title">
          Edit Product
        </Typography>
        <img style={{height: 100}} src={this.state.editProductImage} onClick={() => this.refs.fileUploader.click()} /><br/>
        <TextField
          id="productName"
          label="Name"
          className={classes.textField}
          value={this.state.editProductName}
          onChange={(event) => this.setState({editProductName: event.target.value})}
          margin="normal"
        />
        <TextField
          id="productSize"
          label="Size"
          className={classes.textField}
          value={this.state.editProductSize}
          onChange={(event) => this.setState({editProductSize: event.target.value})}
          margin="normal"
        />
        <TextField
          id="productPrice"
          label="Price"
          className={classes.textField}
          value={this.state.editProductPrice}
          onChange={(event) => this.setState({editProductPrice: event.target.value})}
          margin="normal"
        />
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="legend">Subscription</FormLabel>
          <RadioGroup
            aria-label="subscription"
            name="subscription1"
            value={this.state.editProductSubscription}
            onChange={(event) => this.setState({editProductSubscription: event.target.value})}
          >
            <FormControlLabel value='true' control={<Radio />} label="Is subscription" />
            <FormControlLabel value='false' control={<Radio />} label="Is not subscription" />
          </RadioGroup>
        </FormControl>
        <div>
          <Button style={{marginRight: 50}} onClick={() => this._removeProduct()} color="secondary" variant="contained">
            Remove
          </Button>
          <Button onClick={() => this._submitEditProduct()} color="primary" variant="contained">
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );

  _editImage = async (event) => {
    const editProductImage = await this._getBase64(event.target.files[0])
    this.setState({editProductImage})
  }

  _getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    })
  }

  render() {
    return (
      <React.Fragment>
        <CssBaseline />
        <ButtonAppBar />
        <input type="file" id="file" ref="fileUploader" style={{display: "none"}} onChange={(event) => this._editImage(event)}/>
        <ToastContainer />
        <div style={pageMargin}>
          <div style={horizontalFlex}>
            <div style={horizontalFlex}>
              <Typography variant="h4" gutterBottom component="h2">
                Products
              </Typography>
            </div>
            <div style={buttonFlex}>
              <Button variant="contained" color="primary" onClick={() => this._createProduct()}>
                Create new product
              </Button>
            </div>
          </div>
          <div>
            <ProductTable handleOpen={(item) => this.handleOpen(item)} />
          </div>
        </div>
        { this._modalComponent(this.props.classes) }
      </React.Fragment>
    );
  }
}


const pageMargin = {
  margin: 50,
}

const horizontalFlex = {
  flex: 1,
  display: 'flex',
}

const buttonFlex = {
  flex: 1,
  display: 'flex',
  justifyContent: 'flex-end',
}

function getModalStyle() {
  return {
    top: `10%`,
    left: `33%`,
  };
}

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
  },
  formControl: {
    margin: theme.spacing.unit * 3,
  },
});

export default withStyles(styles)(ProductScreen);
