import React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';
import moment from 'moment'

import ButtonAppBar from '../../_component/appBar'
import PackageTable from '../../_component/package/packageTable';
import {completePackage} from "../../_api/package";

class PackageScreen extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      id: '',
      items: [],
      open: false,
      status: '',
      name: '',
      address: '',
      email: '',
      phone: '',
      startDate: '',
      endDate: '',
      duration: '',
      deliverWeek: '',
      totalprice: '',
    }
  }

  handleOpen = async (order) => {
    await this.setState({
      id: order._id,
      items: order.items,
      status: order.status,
      startDate: order.created_time,
      endDate: order.end_date,
      deliverWeek: order.deliverweek,
      duration: order.duration,
      name: order.user.name,
      address: order.user.address,
      email: order.user.email,
      phone: order.user.tel || '-',
      totalPrice: order.total_price,
    })
    console.log(this.state)
    this.setState({open: true})
  };

  handleClose = () => {
    this.setState({open: false});
  }

  _renderButton = () => {
    if (this.state.status === 'pending') {
      return (
        <Button color="primary" variant="contained" onClick={() => {
          completePackage({id: this.state.id})
          this.setState({open: false})
        }}>
          Deliver
        </Button>
      )
    }
  }

  _modalComponent = (classes) => (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={this.state.open}
      onClose={this.handleClose}
    >
      <div style={getModalStyle()} className={classes.paper}>
        <Typography style={orderTextMargin} variant="h6" id="modal-title">
          รายละเอียดลูกค้า
        </Typography>
        <Typography>ชื่อ: {this.state.name}</Typography>
        <Typography>ที่อยู่: {this.state.address}</Typography>
        <Typography>อีเมล์: {this.state.email}</Typography>
        <Typography style={orderTextMargin}>เบอร์โทรศัพท์: {this.state.phone}</Typography>
        <Typography style={orderTextMargin} variant="h6" id="modal-title">
          รายการสั่งซื้อ
        </Typography>
        <Typography>ระยะเวลา: {this.state.duration} เดือน</Typography>
        <Typography>สัปดาห์ในการจัดส่ง: {this.state.deliverWeek}</Typography>
        <Typography>วันที่เริ่มต้น: {moment(this.state.startDate).format('LL')}</Typography>
        <Typography style={orderTextMargin}>วันที่สิ้นสุด: {moment(this.state.endDate).format('LL')}</Typography>
        {
          this.state.items.map((item) => (
            <Typography>{item.name} {item.size} จำนวน {item.amount} ชิ้น</Typography>
          ))
        }
        <Typography>ราคารวม {this.state.totalPrice} บาท</Typography>
        {this._renderButton()}
      </div>
    </Modal>
  );

  render() {
    const {classes} = this.props;

    return (
      <React.Fragment>
        <CssBaseline/>
        <ButtonAppBar/>
        <div style={pageMargin}>
          <Typography variant="h4" gutterBottom component="h2">
            Packages
          </Typography>
          <div>
            <PackageTable handleOpen={(item) => this.handleOpen(item)}/>
          </div>
        </div>
        {this._modalComponent(this.props.classes)}
      </React.Fragment>
    );
  }
}

PackageScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const pageMargin = {
  margin: 50,
}

const orderTextMargin = {
  marginBottom: 20,
}

function getModalStyle() {
  return {
    top: `12%`,
    left: `33%`,
  };
}

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
  },
  formControl: {
    margin: theme.spacing.unit * 3,
  },
});

export default withStyles(styles)(PackageScreen);
