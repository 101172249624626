import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { Edit } from '@material-ui/icons';

import { getProduct } from '../../_api/product'

class ProductTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      products: []
    }
  }

  componentDidMount = async () => {
    const res = await getProduct()
    const products = res.data
    await this.setState({products})
  }

  render() {
    const { classes } = this.props;

    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <CustomTableCell>Name</CustomTableCell>
              <CustomTableCell>Size</CustomTableCell>
              <CustomTableCell>Price</CustomTableCell>
              <CustomTableCell>Subscription Product</CustomTableCell>
              <CustomTableCell></CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.products.map(product => {
              console.log({product})
              return (
                <TableRow className={classes.row} key={product._id}>
                  <CustomTableCell>{product.name}</CustomTableCell>
                  <CustomTableCell>{product.size}</CustomTableCell>
                  <CustomTableCell>{product.price}</CustomTableCell>
                  <CustomTableCell>{product.is_package.toString()}</CustomTableCell>
                  <CustomTableCell><Button onClick={() => this.props.handleOpen(product)}><Edit /></Button></CustomTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

ProductTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
});

export default withStyles(styles)(ProductTable);